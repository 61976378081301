import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Text,
  Link as UILink,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { logos } from "@hydropop-io/calciupop";
import type { ActionArgs } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { Form, Link, useTransition } from "@remix-run/react";
import { accountSession } from "~/lib/cookies";
import authImage from "../assets/auth-bg.jpg";

export default function Index() {
  const titleColor = useColorModeValue("brand.300", "brand.200");
  const textColor = useColorModeValue("gray.400", "white");
  const transition = useTransition();

  return (
    <Box w="100%">
      <Box w="100%">
        <Box w="100%">
          <Flex position="relative" mb="40px">
            <Flex
              h={{ sm: "initial", md: "75vh", lg: "85vh" }}
              w="100%"
              maxW="1044px"
              mx="auto"
              justifyContent="space-between"
              mb="30px"
              pt={{ sm: "100px", md: "0px" }}
            >
              <Flex
                alignItems="center"
                justifyContent="start"
                style={{ userSelect: "none" }}
                w={{ base: "100%", md: "50%", lg: "42%" }}
              >
                <Flex
                  direction="column"
                  w="100%"
                  background="transparent"
                  p="48px"
                  mt={{ md: "150px", lg: "80px" }}
                >
                  <Heading color={titleColor} fontSize="32px" mb="10px">
                    Welcome Back
                  </Heading>
                  <Text
                    mb="36px"
                    ms="4px"
                    color={textColor}
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Enter your email and password to sign in
                  </Text>
                  <Form method="post">
                    <FormControl>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Email
                      </FormLabel>
                      <Input
                        name="email"
                        mb="24px"
                        fontSize="sm"
                        type="text"
                        placeholder="Your email adress"
                        size="lg"
                      />
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Password
                      </FormLabel>
                      <Input
                        name="password"
                        mb="16px"
                        fontSize="sm"
                        type="password"
                        placeholder="Your password"
                        size="lg"
                      />
                      <Button
                        isLoading={transition.state !== "idle"}
                        fontSize="16px"
                        type="submit"
                        bg="brand.300"
                        w="100%"
                        h="45"
                        mb="20px"
                        color="white"
                        mt="10px"
                        _hover={{
                          bg: "brand.200",
                        }}
                        _active={{
                          bg: "brand.400",
                        }}
                      >
                        SIGN IN
                      </Button>
                    </FormControl>
                  </Form>
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    maxW="100%"
                    mt="0px"
                  >
                    <Text color={textColor} fontWeight="medium">
                      Don&apos;t have an account?
                      <Link to={"/signup"}>
                        <UILink
                          color={titleColor}
                          as="span"
                          ms="5px"
                          fontWeight="bold"
                        >
                          Sign Up
                        </UILink>
                      </Link>
                    </Text>
                    <Text color={textColor} fontWeight="medium">
                      Forgot your password?
                      <Link to={"/recover-password"}>
                        <UILink
                          color={titleColor}
                          as="span"
                          ms="5px"
                          fontWeight="bold"
                        >
                          Recover password
                        </UILink>
                      </Link>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Box
                bgImage={authImage}
                bgSize="cover"
                display={{ base: "none", md: "block" }}
                overflowX="hidden"
                h="100%"
                w="40vw"
                position="absolute"
                right="0px"
                borderBottomLeftRadius="15px"
              >
                <VStack h="100%" justify="center">
                  <Icon
                    as={logos.wide}
                    w="362px"
                    h="64px"
                    color={"white"}
                    textAlign="center"
                  />
                  <Text
                    color="white"
                    textAlign="center"
                    fontSize="34px"
                    fontWeight={300}
                  >
                    Full headless shop stack.
                  </Text>
                </VStack>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box px="24px" mx="auto" width="1044px" maxW="100%"></Box>
    </Box>
  );
}

export async function action({ request }: ActionArgs) {
  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await accountSession.parse(cookieHeader)) || {};

  const body = await request.formData();
  const response = await fetch(
    `${process.env.HYDROPOP_API}account/auth/login`,
    {
      method: "POST",
      body: JSON.stringify({
        email: body.get("email"),
        password: body.get("password"),
      }),
    }
  );

  const returnUrl = new URL(request.url).searchParams.get("returnUrl");
  if (response.status === 201) {
    const access = await response.json();
    cookie.accessToken = access.accessToken;
    cookie.refreshToken = access.refreshToken;

    return redirect(returnUrl || "/accounts/me", {
      headers: {
        "Set-Cookie": await accountSession.serialize(cookie, {
          maxAge: access.expiresIn,
          domain: `.${process.env.HYDROPOP_ROOT}`,
          path: "/",
        }),
      },
    });
  } else if (response.status === 200) {
    const access = await response.json();
    if (access.challenge === "NEW_PASSWORD_REQUIRED") {
      return redirect(
        `/confirm-password${returnUrl ? `?returnUrl=${returnUrl}` : ""}`
      );
    }
  }
  return {
    hi: 5,
  };
}
